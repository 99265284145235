@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@100;300;400;500;700&display=swap');

@import 'variables';
@import "~bootstrap";

@import 'element-variables';
@import "~element-ui/packages/theme-chalk/src/index";
@import "~font-awesome/scss/font-awesome";

.page-enter-active, .page-leave-active {
  transition: opacity 0.5s, transform 0.5s;
}
.page-enter, .page-leave-to {
  opacity: 0;
}
@each $color, $value in $colors {
  @include bg-variant(".bg-#{$color}", $value, true);
}

@each $color, $value in $colors {
  @include text-emphasis-variant(".text-#{$color}", $value, true);
}

.content-header {
  position: relative;
  padding: 15px 15px 0 15px;

}
.content {

  padding: 15px;
  margin-right: auto;
  margin-left: auto;
}
