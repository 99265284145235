

.logo {
    font-size: 20px;
    line-height: 60px;
    text-align: center;
    color: white;
    display: block;
    float: left;
    width: 180px;
    
    &.logo-collapsed {
        width: 50px;
    }
    
    .logo-lg {
    
    }
}

.el-header {
    background-color: #009846;
}


.el-link {
    color: white;
    
    display: block;
    font-size: 20px;
    line-height: 60px;
    float: left;
    padding: 0 10px;
}
::v-deep {

}
